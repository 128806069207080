import React from "react";
import {
    Alert,
    Button,
    Col,
    Form,
    FormControl,
    InputGroup,
    Row,
    Image,
    Modal
} from "react-bootstrap";
import i18n from "../../../i18n";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../store/main/Main-actions";
import { history } from "../../App";

// Define the ticket price constant. Change this value to update the price in one place.
const PRICE_PER_TICKET = 35; //35

class TicketsAndDonationsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ticket: {
                fullName: "",
                email: "",
                cardNumber: "",
                expMonth: "",
                expYear: "",
                cvc: "",
                location: "",
                ticketCount: 1, // number of tickets
                isBuying: false
            },
            showConfirmModal: false,
            confirmData: null // will store { ticketCount, displayTotalPrice, apiTotalPrice }
        };
    }

    formatCardNumber = (value) => {
        const normalizedVal = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
        let newVal = "";
        for (let i = 0; i < Math.ceil(normalizedVal.length / 4); i++) {
            newVal += normalizedVal.slice(i * 4, (i + 1) * 4) +
                (normalizedVal.length <= (i + 1) * 4 ? "" : " ");
        }
        return newVal;
    };

    // Compute totals and show confirmation modal
    handleTicketSubmit = (e) => {
        e.preventDefault();
        const ticketCount = Number(this.state.ticket.ticketCount) || 1;
        // Use a fixed price of PRICE_PER_TICKET per ticket.
        const displayTotalPrice = (ticketCount * PRICE_PER_TICKET).toFixed(2);
        const apiTotalPrice = (ticketCount * PRICE_PER_TICKET).toFixed(2);
        this.setState({
            confirmData: { ticketCount, displayTotalPrice, apiTotalPrice },
            showConfirmModal: true
        });
    };

    handleConfirmPurchase = async () => {
        this.setState(prevState => ({
            ticket: { ...prevState.ticket, isBuying: true },
            showConfirmModal: false
        }));
        const { apiTotalPrice } = this.state.confirmData;
        await this.props.buyTicket(
            this.state.ticket.fullName,
            this.state.ticket.email,
            apiTotalPrice, // total price in dollars (ticketCount * PRICE_PER_TICKET)
            this.state.ticket.cardNumber.replace(/\s+/g, ""),
            this.state.ticket.expMonth,
            this.state.ticket.expYear,
            this.state.ticket.cvc,
            this.state.ticket.location,
            this.state.ticket.ticketCount // number of tickets purchased
        );
        if (!this.props.errorMessage) {
            history.push("/result");
        }
        this.setState(prevState => ({
            ticket: { ...prevState.ticket, isBuying: false }
        }));
    };

    handleCancelPurchase = () => {
        this.setState({ showConfirmModal: false });
    };

    render() {
        const ticketCount = Number(this.state.ticket.ticketCount) || 1;
        const displayTotalPrice = (ticketCount * PRICE_PER_TICKET).toFixed(2);
        return (
            <div className="container">
                {/* Header */}
                <Row className="mb-5 justify-content-center">
                    <Col className="text-center" xs={9}>
                        <Image
                            src="../assets/imgs/NotBorForWar.png"
                            alt="Concert Info"
                            fluid
                        />
                    </Col>
                </Row>
                {/* Buy Ticket Form */}
                <Row>
                    <Col className="text-center" xs md={{ span: 8, offset: 2 }}>
                        <p>Fill in your details to purchase your ticket.</p>
                        <Form onSubmit={this.handleTicketSubmit}>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="ticket-name">&#128100;</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    required
                                    value={this.state.ticket.fullName}
                                    onChange={(e) =>
                                        this.setState({
                                            ticket: { ...this.state.ticket, fullName: e.target.value }
                                        })
                                    }
                                    placeholder={i18n.t("buyTicket.name", "Full Name")}
                                    aria-label="Name"
                                    aria-describedby="ticket-name"
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="ticket-email">@</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    required
                                    value={this.state.ticket.email}
                                    onChange={(e) =>
                                        this.setState({
                                            ticket: { ...this.state.ticket, email: e.target.value }
                                        })
                                    }
                                    placeholder={i18n.t("buyTicket.email", "Email")}
                                    aria-label="Email"
                                    aria-describedby="ticket-email"
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <FormControl
                                    required
                                    value={this.state.ticket.cardNumber}
                                    onChange={(e) => {
                                        const formatted = this.formatCardNumber(e.target.value);
                                        e.target.value = formatted;
                                        this.setState({
                                            ticket: { ...this.state.ticket, cardNumber: formatted }
                                        });
                                    }}
                                    placeholder={i18n.t("buyTicket.cardNumber", "Card Number")}
                                    aria-label="Card number"
                                    aria-describedby="card-number"
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <FormControl
                                    required
                                    type="number"
                                    value={this.state.ticket.expMonth}
                                    onChange={(e) =>
                                        this.setState({
                                            ticket: { ...this.state.ticket, expMonth: e.target.value }
                                        })
                                    }
                                    placeholder={i18n.t("buyTicket.expMonth", "Exp Month")}
                                    aria-label="Expiration Month"
                                    aria-describedby="exp-month"
                                />
                                <FormControl
                                    required
                                    type="number"
                                    value={this.state.ticket.expYear}
                                    onChange={(e) =>
                                        this.setState({
                                            ticket: { ...this.state.ticket, expYear: e.target.value }
                                        })
                                    }
                                    placeholder={i18n.t("buyTicket.expYear", "Exp Year")}
                                    aria-label="Expiration Year"
                                    aria-describedby="exp-year"
                                />
                                <FormControl
                                    required
                                    type="number"
                                    value={this.state.ticket.cvc}
                                    onChange={(e) =>
                                        this.setState({
                                            ticket: { ...this.state.ticket, cvc: e.target.value }
                                        })
                                    }
                                    placeholder={i18n.t("buyTicket.cvc", "CVC")}
                                    aria-label="CVC code"
                                    aria-describedby="cvc-code"
                                />
                            </InputGroup>
                            {/* Location Dropdown */}
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="ticket-state">Select Event</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as="select"
                                    required
                                    value={this.state.ticket.location}
                                    onChange={(e) =>
                                        this.setState({
                                            ticket: { ...this.state.ticket, location: e.target.value }
                                        })
                                    }
                                >
                                    <option value="">Choose Performance</option>
                                    <option value="03/27/25 Ukrainian National Home 7:00 p.m Jersey City,NJ">
                                        03/27/25 Ukrainian National Home 7:00 p.m Jersey City, NJ
                                    </option>
                                    <option value="03/28/25 The Ukrainian National Home 7:00 p.m New York,NY">
                                        03/28/25 The Ukrainian National Home 7:00 p.m New York, NY
                                    </option>
                                    <option value="03/29/25 Ukrainian Catholic National Shrine of The Holy Family 7:00 p.m Washington,DC">
                                        03/29/25 Ukrainian Catholic National Shrine Of The Holy Family 7:00 p.m Washington, DC
                                    </option>
                                    <option value="03/30/25 St.Andrew Ukrainian Orthodox Cathedral 2:00 p.m Silver Spring,MD">
                                        03/30/25 St-Andrew Ukrainian Orthodox Cathedral 2:00 p.m Silver Spring, MD
                                    </option>
                                    <option value="04/04/25 St.Andrew Ukrainian Orthodox Church 7:00 p.m Jamaica Plain,MA">
                                        04/04/25 St-Andrew Ukrainian Orthodox Church 7:00 p.m Jamaica Plain, MA
                                    </option>
                                    <option value="04/05/25 St.Basil Seminary 6:00 p.m Stamford,CT">
                                        04/05/25 St Basil Seminary 6:00 p.m Stamford, CT
                                    </option>
                                    <option value="04/06/25 Ukrainian Educational and Cultural Center 2:00 p.m Jenkintown,PA">
                                        04/06/25 Ukrainian Educational and Cultural Center 2:00 p.m Jenkintown, PA
                                    </option>
                                    <option value="04/09/25 Ukrainian Institute of America 6:30 p.m New York,NY">
                                        04/09/25 Ukrainian Institute of America 6:30 p.m New York, NY
                                    </option>
                                    <option value="04/10/25 Ukrainian Youth Center 7:00 p.m Yonkers,NY">
                                        04/10/25 Ukrainian Youth Center 7:00 p.m Yonkers, NY
                                    </option>
                                    <option value="04/12/25 Ukrainian American Cultural Center 7:00 p.m Whippany,NJ">
                                        04/12/25 Ukrainian American Cultural Center 7:00 p.m Whippany, NJ
                                    </option>
                                </Form.Control>
                            </InputGroup>
                            {/* Number of Tickets Input */}
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="ticket-count">Number of Tickets</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    required
                                    type="number"
                                    value={this.state.ticket.ticketCount}
                                    onChange={(e) => {
                                        const count = parseInt(e.target.value, 10) || 1;
                                        this.setState({
                                            ticket: { ...this.state.ticket, ticketCount: count }
                                        });
                                    }}
                                    min="1"
                                    aria-label="Ticket count"
                                    aria-describedby="ticket-count"
                                />
                                <InputGroup.Append>
                                    <Button
                                        variant="outline-secondary"
                                        onClick={() =>
                                            this.setState((prevState) => ({
                                                ticket: {
                                                    ...prevState.ticket,
                                                    ticketCount: (prevState.ticket.ticketCount || 1) + 1
                                                }
                                            }))
                                        }
                                    >
                                        +
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                            {/* Total Amount Display */}
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="ticket-total-amount">$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    required
                                    type="number"
                                    value={displayTotalPrice}
                                    readOnly
                                    aria-label="Total Amount"
                                    aria-describedby="ticket-total-amount"
                                />
                            </InputGroup>
                            {!!this.props.errorMessage && (
                                <Alert variant="danger" className="mt-3">
                                    {i18n.t(this.props.errorMessage)}
                                </Alert>
                            )}
                            <Form.Group className="pt-3 d-flex justify-content-between">
                                <Button
                                    variant="warning btn-lg btn-block"
                                    type="submit"
                                    disabled={this.state.ticket.isBuying}
                                >
                                    {this.state.ticket.isBuying
                                        ? i18n.t("buyTicket.btnBuying", "Processing...")
                                        : i18n.t("buyTicket.btnBuy", "Buy Ticket")}
                                </Button>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                <hr />
                {/* Video Card with Donation Button */}
                <Row>
                    <Col className="text-center" xs md={{ span: 8, offset: 2 }}>
                        <h2 className="p-3 text-secondary">
                            {i18n.t("buyTicket.title", "Donate to 3rd Assault Brigade")}
                        </h2>
                        <p className="text-center">
                            {i18n.t("buyTicket.subTitle", "Donate to support the fight.")}
                        </p>
                        <div className="card">
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe
                                    className="embed-responsive-item"
                                    src="https://www.youtube.com/embed/lnX2vuMTyeM?controls=0&showinfo=0&rel=0&autoplay=0&playsinline=1&enablejsapi=1&origin=https%3A%2F%2Fstory.ab3.army&widgetid=1"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title="Embedded Video"
                                />
                            </div>
                            <div className="card-body text-center">
                                <a
                                    href="https://buy.stripe.com/dR6eWPe0a7EL6tibIK"
                                    className="btn btn-warning btn-lg btn-block"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Donate Now
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>
                {/* Confirmation Modal */}
                <Modal
                    show={this.state.showConfirmModal}
                    onHide={this.handleCancelPurchase}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Purchase</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Confirm purchase of {this.state.confirmData?.ticketCount} ticket(s) for $
                            {this.state.confirmData?.displayTotalPrice}?
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.handleConfirmPurchase}>
                            Confirm
                        </Button>
                        <Button variant="secondary" onClick={this.handleCancelPurchase}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default connect(
    (state) => state.mainReducer,
    (dispatch) => bindActionCreators(actionCreators, dispatch)
)(withTranslation()(TicketsAndDonationsPage));
